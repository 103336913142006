/* eslint-disable no-template-curly-in-string */
export default {
	REPORT: "Report",
	PUBLISHER: "Publisher",
	SCHEDULED_REPORT: "Scheduled Report",
	SITE: "Site",
	PLACEMENT: "Placement",
	ROUTER: "Router",
	ADVERTISER: "Advertiser",
	INSERTION_ORDER: "Insertion Order",
	CAMPAIGN: "Campaign",
	MEDIA: "Media",
	PROGRAMMATIC_DEMAND: "Programmatic Demand",
	BIDDER: "Bidder",
	USER: "User",
	ROLE: "Role",
	COMPANY: "Company",
	COMPANIES: "Companies",
	AUDIENCE: "Audience",
	TRACKER: "Tracker",
	DATA_DISTRIBUTION: "Data Distribution",
	USER_SETTINGS: "User Settings",
	TAB_LABEL_SETTINGS: "Settings",
	TAB_LABEL_QUALITY: "Quality",
	TAB_LABEL_ALIGNMENTS: "Alignments",
	TAB_LABEL_DEFAULTS: "Defaults",
	SUPPLY_INDEX_SCREEN_DISCLAIMER_CAPTION:
		"* Opportunities, Impressions, and Revenue are estimates based on yesterday’s data.",
	DEMAND_INDEX_SCREEN_DISCLAIMER_CAPTION:
		"* Impressions and Gross Revenue are estimates based on yesterday’s data.",
	COLUMN_HEADER_STATUS: "Status",
	COLUMN_HEADER_DESCRIPTION: "Description",
	COLUMN_HEADER_OPPORTUNITIES: "Opportunities",
	COLUMN_HEADER_IMPRESSIONS: "Impressions",
	COLUMN_HEADER_REVENUE: "Revenue ($)",
	COLUMN_HEADER_GROSS_REVENUE: "Gross Revenue ($)",
	COLUMN_HEADER_START_DATE: "Start",
	COLUMN_HEADER_END_DATE: "End",
	COLUMN_HEADER_CPM: "CPM ($)",
	COLUMN_HEADER_GUID: "GUID",
	FIELD_LABEL_STATUS: "Status",
	STATUS_OPTION_LABEL_ACTIVE: "Active",
	STATUS_OPTION_LABEL_INACTIVE: "Inactive",
	MAX_STRING_LENGTH_EXCEEDED: "Maximum length is ${max} characters",
	MAX_NUMBER_VALUE_EXCEEDED: "Must be ${max} or less",
	OPTION_LABEL_ENABLE: "Enable",
	OPTION_LABEL_DISABLE: "Disable",
	OPTION_LABEL_ENABLED: "Enabled",
	OPTION_LABEL_DISABLED: "Disabled",
	OPTION_LABEL_YES: "Yes",
	OPTION_LABEL_NO: "No",
	PRIORITY_OPTION_BACKFILL: "Backfill",
	REQUIRED_FIELD_WARNING: "Field is required",
	PERCENTAGE_FIELD_MUST_BE_POSITIVE: "Must be a non-negative number",
	PERCENTAGE_FIELD_MUST_BE_BETWEEN_0_100: "Must be between 0-100",
	PERCENTAGE_FIELD_MUST_BE_BETWEEN_0001_100: "Must be between 0.0001-100",
	UNABLE_TO_RETRIEVE_OPTIONS_WARNING:
		"Unable to retrieve options. Please refresh your page.",
	ACTION_BUTTON_LABEL_DOWNLOAD_TAGS_TXT: "Download Tags",
	ACCESSIBILITY_LABEL_TRIGGERS_DOWNLOAD: "triggers download",
	FETCHING_OPTIONS: "Fetching Options...",
	NO_OPTIONS_AVAILABLE: "No Options Available!",
	INVALID_DOMAIN_WARNING: "Must be a valid domain",
	GENERIC_SERVER_ERROR_MESSAGE: "Something went wrong, please try again",
	TYPE_ATLEAST_3_CHARS: "Type at least 3 characters",
	TIMEZONE: {
		UTC: "Coordinated Universal Time (UTC)",
		ET: "Eastern Time",
		CT: "Central Time",
		PT: "Pacific Time",
		MT: "Mountain Time"
	},
	SAVE: "Save",
	CANCEL: "Cancel",
	CLOSE: "Close",
	DELETE: "Delete",
	DELETE_RESOURCE_SUCCESS_MESSAGE: resourceName =>
		`${resourceName} was successfully deleted`
};
