export default {
	ADD_SEGMENT_BUTTON_LABEL: "Add Segment",
	ADD_SEGMENT_MODAL_LABEL: "Add Segment",
	EDIT_SEGMENT_MODAL_LABEL: "Edit Segment",
	COLUMN_HEADER: {
		SEGMENT_NAME: "Segment Name",
		UPLOAD: "Upload",
		DEVICE_REACH: {
			LABEL: "# of Devices",
			TOOLTIP:
				"# of unique devices that have finished processing and are available for targeting"
		}
	},
	SAVE_SUCCESS_MESSAGE: name => `${name} saved successfully`,
	FIELD: {
		SEGMENT_NAME: {
			LABEL: "Segment Name"
		},
		UPLOAD: {
			LABEL: "Upload"
		}
	},
	GRID_ARIA_LABEL: "Segments table",
	FILE_UPLOAD_DIALOG_TITLE: "Upload Segment File",
	FILE_UPLOAD_ARIA_LABEL: "Upload File",
	FILE_UPLOAD_LABEL: "Upload Segment File",
	UPLOAD_BUTTON_LABEL: "Upload",
	UPLOADING_BUTTON_LABEL: "Uploading...",
	FILE_UPLOADED_MESSAGE: fileName =>
		`Segment file ${fileName} uploaded successfully.`,
	FILE_UPLOAD_FAILED_MESSAGE: fileName => `Failed to upload file ${fileName}`,
	DELETE_MODAL: {
		TITLE: "Delete Segment",
		DESCRIPTION: segmentName =>
			`You are about to delete the segment: ${segmentName}. This action cannot be undone.`
	}
};
