import React, { useCallback, useEffect, useMemo, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Tooltip from "@mui/material/Tooltip";

import BaseDataGrid from "../BaseDataGrid/BaseDataGrid";
import LoadingBackdrop from "../LoadingBackdrop/LoadingBackdrop";
import { useResourceAsync, Operations } from "../../hooks/useResourceAsync";

import lc from "./localeContent";
import SegmentFileUpload from "./SegmentFileUpload";
import SegmentResourceModal from "./SegmentResourceModal";
import GenericTableRowActionMenu, {
	ACTION_MENU_ITEM_TYPES,
	tableRowActionMenuColumnDefaults
} from "../GenericTableRowActionMenu/GenericTableRowActionMenu";
import commonLocaleContent from "../../screens/commonLocaleContent";

function SegmentTable({ dataDistributionId }) {
	const [fileUploadSegmentId, setFileUploadSegmentId] = useState("");
	const [initialSegmentFormValues, setInitialSegmentFormValues] = useState(
		null
	);

	const dataDistributionEndpoint = useMemo(
		() => `manage/dataDistributions/${dataDistributionId}/segments`,
		[dataDistributionId]
	);
	// TODO: Implement error handling
	const {
		data: indexData,
		isLoading: isIndexLoading,
		execute: requestIndexData
	} = useResourceAsync(dataDistributionEndpoint, Operations.LIST);

	// Fetch the data when the data distribution ID changes. Practically speaking, this should only happen once when the form loads
	useEffect(() => {
		if (dataDistributionId) {
			requestIndexData();
		}
	}, [dataDistributionId, requestIndexData]);

	const openSegmentAddForm = () => {
		setInitialSegmentFormValues({ name: "" });
	};

	const openFileUploadForm = id => {
		setFileUploadSegmentId(id);
	};

	// Delete functionality
	const [deletedItems, setDeletedItems] = useState([]);
	const deleteItem = useCallback(
		itemId => setDeletedItems([...deletedItems, itemId]),
		[deletedItems]
	);

	const columns = useMemo(() => {
		return [
			{
				...tableRowActionMenuColumnDefaults,
				renderCell: ({ row }) => (
					<GenericTableRowActionMenu
						rowData={row}
						menuOptions={[
							{
								key: "DELETE",
								type: ACTION_MENU_ITEM_TYPES.deleteResource,
								deleteItemCallback: deleteItem,
								label: commonLocaleContent.DELETE,
								title: lc.DELETE_MODAL.TITLE,
								description: lc.DELETE_MODAL.DESCRIPTION(row.name),
								confirmLabel: commonLocaleContent.DELETE,
								resourceEndpoint: "manage/segments",
								successMessage: commonLocaleContent.DELETE_RESOURCE_SUCCESS_MESSAGE(
									row.name
								)
							}
						]}
					/>
				)
			},
			{ field: "name", headerName: lc.COLUMN_HEADER.SEGMENT_NAME, flex: 1 },
			{
				field: "deviceReach",
				renderHeader: () => (
					<Tooltip title={lc.COLUMN_HEADER.DEVICE_REACH.TOOLTIP}>
						<span aria-label={lc.COLUMN_HEADER.DEVICE_REACH.LABEL}>
							{lc.COLUMN_HEADER.DEVICE_REACH.LABEL}
						</span>
					</Tooltip>
				),
				flex: 0.5,
				valueFormatter: ({ value }) => value.toLocaleString(),
				ariaLabel: lc.COLUMN_HEADER.DEVICE_REACH.LABEL
			},
			{
				field: "upload",
				headerName: lc.COLUMN_HEADER.UPLOAD,
				align: "center",
				headerAlign: "center",
				pinnable: false,
				renderCell: ({ row }) => (
					<IconButton
						aria-label={lc.FILE_UPLOAD_ARIA_LABEL}
						aria-haspopup="true"
						onClick={() => openFileUploadForm(row.id)}
						color="primary"
					>
						<FileUploadIcon />
					</IconButton>
				),
				sortable: false,
				filterable: false,
				disableColumnMenu: true,
				flex: 0
			}
		];
	}, [deleteItem]);

	const filteredIndexData = useMemo(() => {
		if (!indexData) return [];
		return indexData.filter(row => !deletedItems.includes(row.id));
	}, [indexData, deletedItems]);

	return (
		<Paper>
			<LoadingBackdrop isOpen={isIndexLoading} />
			<BaseDataGrid
				rows={filteredIndexData}
				columns={columns}
				paginationConfig={{ defaultRowsPerPage: 10 }}
				defaultSortConfig={{ field: "name", direction: "asc" }}
				gridHeight={450}
				disableColumnSelector
				disableDensitySelector
				tableActionButton={
					<Button
						variant="contained"
						onClick={openSegmentAddForm}
						type="button"
						color="secondary"
						startIcon={<AddIcon />}
					>
						{lc.ADD_SEGMENT_BUTTON_LABEL}
					</Button>
				}
				gridAriaLabel={lc.GRID_ARIA_LABEL}
			/>
			<SegmentResourceModal
				initialFormValues={initialSegmentFormValues}
				dataDistributionId={dataDistributionId}
				onFormSubmitSuccess={() => requestIndexData()}
			/>
			<SegmentFileUpload
				setSegmentId={setFileUploadSegmentId}
				segmentId={fileUploadSegmentId}
			/>
		</Paper>
	);
}

SegmentTable.propTypes = {
	dataDistributionId: PropTypes.string.isRequired
};

export default SegmentTable;
